<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active">關於計畫</li>
              <li class="breadcrumb-item active" aria-current="page">
                計畫簡介(原住民語)Kuzakuza tu sinpakisniap
              </li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold"
                  >計畫簡介(原住民語) Kuzakuza tu sinpakisniap</span
                >
              </h2>
            </div>
            <p class="lh-lg font-1-2em">
              東南科技大學與國立臺灣圖書館合作執行教育部補助社教機構數位人文計畫，本年度以館方出版的《蕃人觀光日誌》為基礎，開發數位化教材、互動體驗學習內容及相關衍生設計。不但以國立臺灣圖書館做為人文社會科學知識應用與價值體現之實踐場域，同時更結合東南科技大學數位科技專業，展現人文創新特色，並藉由樟樹高中進行後續之教學展演及推廣。
            </p>
            <p class="lh-lg font-1-2em">
              【東南科技zaingaku】 muskun Taivang paldaimpusan patasan tu
              zangkitai malkasia 【教育部】 mindangaz zangkitai mapapatas tu
              sinkuzakuza, maaq haipti tu painsanan qai, sia sinpatas tu
              《Takisilazan miniqumis dinulap tu inundadaanan》 kanadaan,
              pin’uniun sinpapatas, mapa’uskun mapasnava tu sinkuzakuza. Nii tu
              kaupa napin’uniun Taivang dadaimpusan patasan itusia bunun
              kaitaiklasan tu ikma’iun siin ispasnava, musasuu tu mapa’uskun i
              【東南科技zaingaku】matatas’i qanigu tu kaisambutan, mapinsuma
              baqlu tu kaiqansiapan, musasuu kanahaan 【樟樹pasnanava’an
              daing】kuzakuza ki mankinuzin tu mapasnava siin mapititpung tu
              sinkuzakuza.
            </p>
            <p class="lh-lg font-1-2em">
              《蕃人觀光日誌》一書係由臺灣總督府技師藤根吉春編撰，內容詳載1897年8月，總督府第一次安排臺灣原住民赴日參訪的行程內容，包括10位參與原住民的背景資料、29天隨行者的觀察紀錄，以及作者藤根吉春的事後檢討與建議。
            </p>
            <p class="lh-lg font-1-2em">
              《Takisilazan miniqumis dinulap tu inundadaanan》 tu patasan qai,
              sia Taivang sotohu tu liskadaan 藤根吉春(Fujine Yoshiharu)
              maipatas, mapatas misnahaan 1897 painsanan 8 buan, minus’anang
              Taivang sotohu madas Taivang Takisilazan tu miniqumis munhaan
              Lipuun tantungu tu inundadaanan. Haiza mas’an taintungu bunun tu
              iniqumisan, 29 diqanin isia makilansan bunun tu sinsaduu sinpatas,
              siin mapapatas tu tais’aan 藤根吉春(Fujine Yoshiharu) ihaan
              pankanaqtung sinkuzakuza tu sinpaliqansiap siin iniliskinan.
            </p>
            <p class="lh-lg font-1-2em">
              透過這份參訪報告書，我們不僅看到臺灣原住民在踏上日本國土時，對於眼前異國風情與新奇事物，顯露出的驚訝與不解；同時，在藤根氏的描述下，臺灣原住民與日本統治者間就其族群意識、生活與文化觀點的差異，都清晰地被呈現在他們之間的對話與行為表現裡，這也成為書中最值得關注省思的焦點。
            </p>
            <p class="lh-lg font-1-2em">
              Kanahaan sinpapatastun, nii ata kaupa usaduu’an Taivang
              Takisilazan miniqumis laupang tunahaan Lipuun tu qani’anan, maaq
              tu sinaduu’in ihaan vaivi siduq tu iniqumisan siin makitvaivi tu
              sinkuzakuza tu tiningquza’an siin inpanu’an. Musasuu, ihaan
              藤根吉春(Fujine Yoshiharu) tu sinpapatas, pitqasun isia Taivang
              Takisilazan miniqumis siin sasaipuk tu Lipuun miniqumis ihaan
              iniliskinan, iniqumisan siin kaiqansiapan nii tu maszang tu
              dangi’anan, min’uni ihaan patasantun sinpatas mastaan akitmata’un
              tu sinpapatas.
            </p>
            <p class="lh-lg font-1-2em">
              以下概述其行程：首先是8月2日，原住民齊集臺北後，來到總督府，由總督代理立見尚文說明此行目的並給予訓示。8月3日，從臺北出發，乘坐火車前往基隆，當天隨即搭上釜山丸前往日本。經過幾日海上航行後，8月6日抵達首站長崎，簡單參觀工廠後，繼續啟航北上。8月7日經過門司，進入瀨戶內海，8日船行至宇品（今之廣島），見識了日本的軍事設施與後勤補給。
            </p>
            <p class="lh-lg font-1-2em">
              Maupata inundadaanan tu: Tangus qai ihaan 8 buan tu 2 diqanin,
              ihaan Takisilazan tu miniqumis Taihuku mu’ampuk a, munhaan Sotohu,
              sia sasbinaz 立見尚文(Tatsumi Naohumi) tataqu pakisniap i
              na’inundaanan siin sinpatqaulung tu qalinga. 8 buan tu 3 diqanin,
              misnahaan Taihuku kitngaa mudaan, tunhaan kisia munhaan Kilung,
              tudiip tu qani’an tunahaan 釜山丸(Busan) tu qatu munhaan Lipuun.
              Talpiaq diqanin tu tunqatu’an a, ihaan 8 buan tu 6 diqanin
              tunahaan 長崎(Nagasaki ) tu dalaqan, mukhivang tantungu ka,
              muqnang mudakis tunqatu. 8 buan tu 7 diqanin, aqngqai 門司(Moji) ,
              munkumbu ihaan 瀨戶 (Seto) ning’av tu dalaqan, 8 tu diqanin
              tunqatu tunahaan 宇品(Ujina)（今之廣島）(Hiroshima), usaduu’anin
              isia Lipuun ispasampanaq tu sintatas’i siin masasatu ikma’aiu’un
              qaimangsut tu sinkuzakuza.
            </p>
            <p class="lh-lg font-1-2em">
              9日抵達神戶，參觀川崎造船廠與楠公神社，看到日本整齊的市容與乾淨的街道。10日一早，由神戶改搭火車前往名古屋，行程中看見日本農田運作情形以及各種農作物樣貌，同時也參觀當地的織物工廠。11日抵達最後終點站--東京，直到21日才離開。
            </p>
            <p class="lh-lg font-1-2em">
              9 diqanin tunahaan 神戶 (Kōbe) , mintivi 川崎(Kawasaki) matatas’i
              qatu tu koziu siin 楠公(Nanko) Aki’ia tu susumsuman, usaduu’an
              isia Lipuun mabauszang takida’an siin maningzav tu daan. 10
              diqanin tu tingmut, misnahaan 神戶(Kōbe) tunkisia munhaan
              名古屋(Nagoya), ihaan inundadaanan usaduu’an isia Lipuun
              mungququma tu sinkuzakuza siin vaivivaivi tu sinsusuaz, musasuu
              tu, mintivi amin matiti’un tu koziu. 11 diqanin, tunahaanin nanu
              tu tantungu’an -- 東京( Tōkyō), sauhaan 21 diqanin tudiip
              mudaan.
            </p>
            <p class="lh-lg font-1-2em"></p>
            <p class="lh-lg font-1-2em">
              其間參觀了皇城、二重橋，登上愛宕山眺望東京，到帝國飯店拜見臺灣總督乃木希典，並參觀飯店的設施及庭園，在旅店湯屋沐浴後換上訂製的新和服。另外，也去參觀上野公園、博物館與動物園，並到淺草看看當地的市集活動，觀賞迴轉畫與雜技表演。此外，也安排參觀鐘淵紡紗會社、凌雲閣，參拜靖國神社與遊就館。其後，還特別安排到青山練兵場、東京砲兵工廠、射擊學校觀看部隊演習與火炮射擊，以及參觀芝區赤羽造兵所（東京海軍兵工廠）、橫須賀造船廠及廠內軍艦。
            </p>
            <p class="lh-lg font-1-2em">
              Maaq tu tantungu ka, saduu ki Lipuun (tennō) tu takida’an,
              二重(Nijubashi) tu hatal, mundaza ihaan 愛宕(Atago) tu ludun saduu
              東京( Tōkyō), munhaan dadangi’an tu sasbinaz liukang mapasaduu ki
              Taivang tu sasbinaz 乃木希典(nogi maresuke), musasuu tu mintivi
              isia liukang tu sinkalumaq siin bukzavan, ihaan liukang tu
              talhisuqan huvaivan Lipuun tu pinainuk. Muqna tu, munhaan 上野(
              Ueno) tu tantungu’an, daimpusan qabas ikma’ai’un siin saduu’an
              takismumuut tu tantungu’an, munhaan 淺草(Asakusa) mintivi taki’ita
              tu babalivan, saduu muqaivusvus tu sinpapatas siin sakasu. Muqna
              mintivi amin 鐘淵(Kanegafuchi ) titin’unan, 凌雲(Ryounkaku)
              taluqan siin 遊就 (Yushukan), munhaan susumsuman masumsum siin
              tantungu. Kinuzin, munhaanang 青山(Aoyama) kunlingan, 東京(
              Tōkyō), matas’i busul tu koziu. pakunlingan mapapanaq tu
              pasnava’an, siin saduu sivitai mapasampanaq tu kunling, mintivi
              芝區赤羽(Akabane) tatas’ian busul, 橫須賀(Yokosuka) tatas’ian qatu
              siin sintas’i tu qatu.
            </p>
            <p class="lh-lg font-1-2em">
              8月21日，觀光團一行正式離開東京，前往大阪。22日臨時在彥根車站下車奉迎日本天皇座車，故而正式看到了天皇。隨後在大阪停留兩日，參觀大阪城、大阪造幣局與大阪砲兵工廠。8月24日，在參觀天王寺與朝日新聞社影印設備後，下午便趕往神戶，搭乘當天出航的依姬丸，返回臺灣。
            </p>
            <p class="lh-lg font-1-2em">
              8 buan tu 21 diqanin, tudiipin tantutungu tu taisia’aan misnahaan
              東京( Tōkyō) mudaan munhaan 大阪( Ōsaka), 22 tu diqanin, mukhiv
              ihaan 彥根(Hikone) tunlaihli’an ampasduu isia (tennō) tu laihli,
              paaqpun, tutuza tu usadu’anin i Lipuun tu (tennō). Kinuzin ihaan
              大阪( Ōsaka) sukdu talpusan diqanin, ihaan 大阪( Ōsaka) tu asang,
              tatas’ian sui siin tatas’ian busul tantungu. 8 buan tu 24 diqanin,
              mintivi 天王(Tennoji) susumsuman siin 朝日新聞社(Asah) tu koziu,
              tungquvali tunahaan 神戶(Kobe), tudaza namunhaan Taivang tu
              依姬丸(Yorihime) qatu musuqais. 
            </p>
            <p class="lh-lg font-1-2em">
              本計畫根據以上文獻所記載之行程，特結合歷史、地理與文學領域專家，進行資料蒐集與內容探討，在儘可能還原19世紀末臺灣原住民的形象與生活背景的前提下，整併其中重要議題，從而提煉出富有趣味與意義的對話及觀點，歸納適合十二年國教學生學習的教材、題庫與教具，同時也具體回應聯合國17項永續發展目標(SDGs)的跨時代需求。
            </p>
            <p class="lh-lg font-1-2em">
              Maaq a sinkuzakuzakun a, kanahaan tanangaus tu sinpapatas,
              mapa’uskun sin’iqumis inunudadaanan, dalaqan siin mapapatas tu
              mamangan, malkasia kikilim tusisingav siin mapapatas tu
              sinkuzakuza, musasuu tu asa tu mapusuqais mapasaduu ihaan 19 tus’a
              tu painsanan isia Taivang Takisilazan miniqumis tu sin’iqumis,
              pa’uskunun almata’an tu sinkuzakuza, mapatas mapinsuma haiza’an
              kainahipan haiza’an iniliskinan tu sinpabazbaz siin iniliskinan.
              Masangkun malasduu isnava’un mas’an qan dusa painsanan isnanava’an
              tu patasan. Ispalatanam siin ispasnava tu ikma’ai’un, musasuu tu
              mapalabaas i ihaan nastu dalaqanti 17 sauqabasqabas mapalsunu
              u’asa’un tu sinkuzakuza.
            </p>
            <p class="lh-lg font-1-2em">
              因此，我們以「世紀末對話：臺灣原住民族的帝國初體驗」為題，設計五個單元動畫短片，透過人物創作與場景建構，繪製並設計相關背景知識體系、故事大綱、學習單等，期以拓展與活化教學之應用。同時，也藉由VR及AR教具的編製，增加學習趣味，用以啟發學生對臺灣人文歷史的興趣，並進而持續探索學習。
            </p>
            <p class="lh-lg font-1-2em">
              Paaqpun, makusia azam “ Ihaan pankanaqtung tus’a tu mapabazbaz:
              Taivang Takisilazan miniqumis mus’anang tantungu Lipuun” pin’uniun
              qaputung, matas’i maihima maputul tu qaningu, kanahaan baqlu tu
              takida’an siin dangi’anan, mapatas mapalhamu kaiqansiapan,
              paliqabasan, palatanamun tu ispapatas, maqtu mapititpung siin
              mapinnahip i masnanava tu kinuzkuza’an, musasuu tu, makusia
              sintas’i tu qaningu(VR) siin mamantuk tu ailqumisan(AR) matas’i
              ispasnava tu ikma’iun, mapinnahip i isnanava’an, mapakisniap
              isnanava’an itusia Taivang miniqumis tu iniqumisan, musasuu tu
              haiza iniliskinan tu nakadadauk nii tu misbu minmaqasmav
              mapasnava.
            </p>
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-brands fa-fw fa-youtube me-3 font-1-5em text-danger"
                ></i
                ><span class="text-dark font-1-2em fw-bold">動畫解說</span>
              </h2>
            </div>
            <div class="w-100 loading" ref="target5">
              <iframe
                width="100%"
                height="700"
                src="https://www.youtube.com/embed/in4hGZdS2zY?si=QhkYkdoaUeyry4e6"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
